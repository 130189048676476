import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { Link } from 'react-scroll';
import { tableOfVoucher } from '../../helpers/firmData';
import './Offer.css';

export const Offer = ({ date }) => {
  console.log(date)
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const tableOfOffers = [
    {id: 1, title: 'Standard', price: `${date.standardPrice} zł`, information: [
      {infoID: 1, text: '30h zajęć teoretycznych'},
      {infoID: 3, text: '30h zajęć praktycznych'},
      {infoID: 2, text: 'Materiały dydaktyczne (podręcznik oraz portal z testami)'},
      {infoID: 4, text: 'Egzamin wewnętrzny'},
    ]},
    {id: 2, title: 'Premium', price: `${date.premiumPrice} zł`, information: [
      {infoID: 1, text: '30h zajęć teoretycznych'},
      {infoID: 3, text: '40h zajęć praktycznych'},
      {infoID: 2, text: 'Materiały dydaktyczne (podręcznik oraz portal z testami)'},
      {infoID: 4, text: 'Egzamin wewnętrzny'},
      {infoID: 6, text: '+', class: true},
      {infoID: 5, text: 'Uchwyt samochodowy do telefonu'},
    ]},
    {id: 3, title: 'Jazdy dodatkowe', price: `${date.additionalDriving} zł`, information: [
      {infoID: 1, text: `Szkolenie uzupełniające`},
      {infoID: 2, text: `dopasowane indywidualnie`},
      {infoID: 3, text: `do potrzeb klienta`},
    ]},
  ];

  const renderTitle = () => {
    return <h1 className='title'>Oferta</h1>
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // RENDER 
  const renderOfferItems = tableOfOffers.map((item) => {
    return (
      <div className='item' key={item.id}>
        <p className="itemTitle">{item.title}</p>
        <p className="price">{item.price}</p>
        <ul>
          {item.information.map((info) => {
            return (
              <li key={info.infoID} className={info.class ? 'bigPlus' : ''}>{info.text}</li>
            )
          })}
        </ul>
        <Link activeClass="active" smooth spy to="contact">
          <p className='buttonAddToCourse'>Zapisz się!</p>
        </Link>
      </div>
    )
  })

  const renderSliderModal = () => {
    return (
      <div className='item flexForVoucher' key={4}>
        <div>
          <p className="itemTitle">{'Bon Podarunkowy'}</p>
          <p className="price">{'Cena do ustalenia'}</p>
          <ul>
            <li key={4}>Podaruj bliskiej osobie</li>
            <li key={5}> możliwość swobody i</li>
            <li key={6}>niezależności w podróżowaniu</li>
        </ul>
        <Link activeClass="active" onClick={() => openImageViewer(0)}>
          <p className='buttonAddToCourse'>Zobacz!</p>
        </Link>
        </div>
      </div>
    )
  }

  return (
    <section className='offerSection'>
      <div className='offerBackground'></div>
      <div className='colorChanger'></div>
      {renderTitle()}
      <div className='offerContainer'>
        {renderOfferItems}
        {renderSliderModal()}
      </div>
      <div>
        {isViewerOpen && (
          <ImageViewer
            class
            src={tableOfVoucher}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </section>
  )
}
